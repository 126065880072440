import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Fashion from "../components/Fashion";

const FashionPage = ({ data }) => (
  <Layout>
    <Fashion data={data} />
  </Layout>
);

export default FashionPage;
export const query = graphql`
  query {
    golffashion: file(relativePath: { eq: "mj-golf-fashion.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
